import React, { Fragment } from "react"
import getContext from "context"
import { Layout } from "~/src/components"
import { Marquee, CardsBlock } from "planetyze-ui"
import { graphql, Query } from "react-apollo"
import query from "./Home.gql"
import VIDEO_QUERY from "./Video.gql"

const Video = ({ item }) => {
  const { t } = getContext()

  if (item) {
    return (
      <Marquee
        src={item.image ? item.image.url : "/img/japan.jpg"}
        title={t("home.title")}
        subtitle={t("home.description")}
        legend={item.title}
        url={item.url}
        centered
        className="-mt-10 md:-mt-20"
        video={item.video ? item.video.code : null}
      />
    )
  }

  return (
    <Marquee
      src={"/img/japan.jpg"}
      title={t("home.title")}
      subtitle={t("home.description")}
      centered
      className="-mt-10 md:-mt-20"
    />
  )
}

const Home = (props) => {
  const { t } = getContext()

  return (
    <Layout
      title={t("seo.home.title")}
      description={t("seo.home.description")}
      src="https://planetyze.com/img/facebook-banner.png"
      pageType="home"
      url={props.uri}>
      <Layout.Loader {...props.data}>
        {({ locations, categories, itineraries }) => {
          return (
            <Fragment>
              <Query query={VIDEO_QUERY} ssr={false}>
                {({ loading, error, data }) => {
                  if (loading) return <Video />
                  if (error) return <Video />

                  return <Video item={data.location.nodes[0]} />
                }}
              </Query>
              <div className="page-container">
                <CardsBlock
                  title={t("blocks.destinations")}
                  url="/japan/destinations"
                  items={locations.nodes}
                  pageInfo={locations.pageInfo}
                  t={t}
                  slider
                />

                <CardsBlock
                  title={t("blocks.popular-itineraries")}
                  items={itineraries.nodes}
                  theme="lg"
                  t={t}
                  slider
                />
                {categories.nodes.map((item) => (
                  <CardsBlock
                    key={`category-${item.id}`}
                    title={t("blocks.popular-category", {
                      category: item.name
                    })}
                    items={item.guidebook.nodes}
                    pageInfo={item.guidebook.pageInfo}
                    url={item.url}
                    t={t}
                    slider
                  />
                ))}
              </div>
            </Fragment>
          )
        }}
      </Layout.Loader>
    </Layout>
  )
}

export default graphql(query)(Home)
